import React, { useContext, useEffect } from 'react';
import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/72x72/57.webp';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { AuthContext } from "components/workflow-mgmt/authentication/context/AuthContext"; // Adjust the import path accordingly
import { useNavigate } from 'react-router-dom';
import { FaCircleUser } from "react-icons/fa6";
/* Umesh - Profile Navigation items after the menus */

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  
  const backend_link = process.env.REACT_APP_API_BASE_URL;
  
  const authContext = useContext(AuthContext);

  if (!authContext) {
      throw new Error("useAuth must be used within an AuthProvider");
  }

  const { isAuthenticated, user, logout } = authContext;

  
  const [navItems] = useState([
    {
      label: 'Profile',
      icon: 'user',
      link: `${backend_link}profile/`,      
    },
    {
      label: 'Dashboard',
      icon: 'pie-chart',
      link: `${backend_link}profile/`,      
    },
    // {
    //   label: 'Posts & Activity',
    //   icon: 'lock'
    // },
    //{
    //  label: 'Settings & Privacy ',
    //  icon: 'settings',
    //  link: `${backend_link}privacy/`
    //},
    {
      label: 'Help Center',
      icon: 'help-circle',
      link: 'https://docs.google.com/document/d/1bWR3tufTCMEU5-eSUf2lwv63X7rPKAh6uoVmqq_-gUM/edit?usp=sharing'
    },
    // {
    //   label: 'Language',
    //   icon: 'globe'
    // }
  ]);

  useEffect(() => {
    if(isAuthenticated){

    }
  },[]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          {isAuthenticated ? (

          <>
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            {user?.profile_pic ? <Avatar src={user?.profile_pic} size="l" /> : <FaCircleUser size={35} /> }
            <h6 className="text-black">{user?.username}</h6>
            {user?.client_name && <div className="small">({user?.client_name})</div> }
          </div>
          {/* <div className="mb-3 mx-3">
            <Form.Control
              type="text"
              placeholder="Update your status"
              size="sm"
            />
          </div> */}
          <div style={{ height: '10rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href={item?.link} target="_blank" className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
          </>
          ) : (
          <>
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <span><FaCircleUser size={20} /></span>
          </div>            
          </>
          )
          }
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          {/* <Nav className="nav flex-column my-3">
            <Nav.Item>
              <Nav.Link href="#!" className="px-3">
                <FeatherIcon
                  icon="user-plus"
                  size={16}
                  className="me-2 text-900"
                />
                <span>Add another account</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr /> */}

          {/* PixelTMS Changes */ }
          {/* Login - Logout - right hand menu - Umesh */}
           <div className="px-3">
            {isAuthenticated ? (
            <Link
              to={`${backend_link}/auth/logout`}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Log out
            </Link>
            ): (
              <Link
              to={`${backend_link}/auth/login`}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-in" className="me-2" size={16} />
              Log In
            </Link>
            )
          }
          </div>
          {/* <div className="my-2 text-center fw-bold fs-10 text-600">
            <Link className="text-600 me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-600 mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-600 ms-1" to="#!">
              Cookies
            </Link>
          </div> */}
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
