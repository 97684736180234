import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/team/40x40/57.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import NineDotMenu from './NineDotMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import ThemeToggler from 'components/common/ThemeToggler';
import { useState, useContext } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
/* Umesh - Navigation items after the menus */
import { AuthContext } from 'components/workflow-mgmt/authentication/context/AuthContext';
import { FaCircleUser } from "react-icons/fa6";

const NavItems = () => {

  const {
    config: { navbarPosition }
  } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  
  const authContext = useContext(AuthContext);

  if (!authContext) {
      throw new Error("useAuth must be used within an AuthProvider");
  }

  const { isAuthenticated, user, logout } = authContext;

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      {/* Notification Icon - Horizontal Menu - Umesh */}
      {/* <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <FeatherIcon icon="bell" size={20} />
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item> */}
      {/* PixelTMS Changes */ }
      {/* Top menu profile pic or login button*/}
      <Nav.Item>
      {isAuthenticated ? (
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            {/* Top menu profile pic */}
            {user?.profile_pic ? <Avatar src={user?.profile_pic} size="l" /> : <FaCircleUser size={27} /> }
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
        ) : (
          <Link
              to={`${process.env.REACT_APP_API_BASE_URL}/auth/login`}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-in" className="me-2" size={16} />
              Log In
            </Link>
          )
        }
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavItems;
