import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece,
  UilLayerGroup,

} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  // {
  //   label: 'dashboard',
  //   horizontalNavLabel: 'home',
  //   active: true,
  //   icon: UilChartPie,
  //   labelDisabled: true,
  //   pages: [
  //     {
  //       name: 'home',
  //       icon: 'pie-chart',
  //       active: true,
  //       flat: true,
  //       pages: [
  //         {
  //           name: 'CRM',
  //           path: '/dashboard/crm',
  //           pathName: 'crm',
  //           topNavIcon: 'phone',
  //           active: true
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    label: 'Workflow Designer',
    horizontalNavLabel: 'Workflow',
    active: true,
    icon: UilLayerGroup,
    labelDisabled: true,
    pages: [
      {
        name: 'Workflow List',
        icon: 'share-2',
        active: true,
        flat: true,
        path: '/crm-admin/workflow-list/',
        pathName: 'crm-admin', 
      },
      // {
      //   name: 'Workflow Steps',
      //   icon: 'pie-chart',
      //   active: true,
      //   flat: true,
      //   path: '/crm-admin/workflow-steps/',
      //   pathName: 'crm-admin', 
      // }      
    ]
  },

  {
    label: 'Workflow Cases',
    horizontalNavLabel: 'Workflow Cases',
    active: true,
    icon: UilDocumentLayoutRight,
    labelDisabled: true,
    pages: [
      {
        name: 'Workflow Cases',
        icon: 'file-text',
        active: true,
        flat: true,
        path: process.env.REACT_APP_API_BASE_URL + 'workflowapp/workflows/',
        pathName: 'crm-admin', 
      },
      {
        name: 'Create Case',
        icon: 'edit',
        active: true,
        flat: true,
        path: process.env.REACT_APP_API_BASE_URL + 'workflowapp/workflows/',
        pathName: 'crm-admin', 
      },
      {
        name: 'Create Form',
        icon: 'grid',
        active: true,
        flat: true,
        path: process.env.REACT_APP_API_BASE_URL + 'workflowapp/my-form/',
        pathName: 'crm-admin', 
      },
      {
        name: 'Task User',
        icon: 'at-sign',
        active: true,
        flat: true,
        path: process.env.REACT_APP_API_BASE_URL + 'task_user_list/',
        pathName: 'crm-admin', 
      }            
    ]
  },

];
