import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';


interface userFields {
    id: string; 
    email: string; 
    username: string; 
    client_name?: string; 
    client_id?: number; 
    profile_pic?: string;    
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: userFields | null;
    login: (data: any) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<userFields | null>(null);

    useEffect(() => {
        const accessToken = Cookies.get('_pixelflow.access_token');
        const refreshToken = Cookies.get('_pixelflow.refresh_token');
        const userId = Cookies.get('_pixelflow.user_id');
        const email = Cookies.get('_pixelflow.email');
        const username = Cookies.get('_pixelflow.username');
        const client_name = Cookies.get('_pixelflow.client_name');

        if (accessToken && refreshToken && userId && email && username && client_name) {
            setIsAuthenticated(true);
            setUser({ id: userId, email, username, client_name });
        } else {
            setIsAuthenticated(false);
            setUser(null);
        }
    }, []);

    const login = async (data: any) => {
        // Handle login logic
        setIsAuthenticated(true);
        setUser(data.user);
    };

    const logout = () => {
        // Handle logout logic
        setIsAuthenticated(false);
        setUser(null);
        // Optionally remove cookies
        Cookies.remove('_pixelflow.access_token');
        Cookies.remove('_pixelflow.refresh_token');
        Cookies.remove('_pixelflow.user_id');
        Cookies.remove('_pixelflow.email');
        Cookies.remove('_pixelflow.username');
        Cookies.remove('_pixelflow.client_name');
    };

    
    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
