import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import SignOutForm from 'components/modules/auth/SignOutForm';
import { useEffect } from 'react';

const SignOut = () => {
  
  useEffect(() => {
    const logoutUrl = process.env.REACT_APP_API_BASE_URL + '/auth/logout';
    window.location.replace(logoutUrl);
  }, []);

  return (
    <AuthSimpleLayout logo={false}>
      <div className="col-6 offset-4 text-center">
      <p>Loggin out..... please wait</p>
      </div>
    </AuthSimpleLayout>
  );
};

export default SignOut;
