import React from 'react';
import { Handle } from 'reactflow';

const DiamondNode = ({ data }) => {
  return (
    <div style={{
      width: 80,
      height: 80,
      fontSize: 12,
      backgroundColor: 'wheat',
      border: '1px solid goldenrod',
      transform: 'rotate(45deg)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div style={{ transform: 'rotate(-45deg)' }} className='text-center'>
        {data.label} 
      </div>
      <Handle type="source" position="bottom" style={{ left: '100%', transform: 'translate(-30%, 0)' }} />
      <Handle type="target" position="top" style={{ left: '0%', transform: 'translate(-70%, 0)' }} />
    </div>
  );
};

export default DiamondNode;
