import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  SmoothStepEdge,
} from 'reactflow';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Api, AuthApi } from 'Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import CustomNode from './CustomNode';
import './WorkflowDesigner.css';
import 'reactflow/dist/style.css';
//import './overview.css';
import ReactSelect from 'components/base/ReactSelect';
import { NodeMenu } from './NodeMenu';
import ModalProcess from './ModalProcess';
import ModalDecision from './ModalDecision';
import CustomEdge from './CustomEdge';
import DiamondNode from './DiamondNode';
import NodeModal from './NodeModal';
import EdgeModal from './EdgeModal'; 
import DecisionModal from './DecisionModal';

// const nodeTypes = {
//   CustomNode: CustomNode
// };

const minimapStyle = {
  height: 120
};

const edgeTypes = {
  default: SmoothStepEdge,
  custom: CustomEdge,
};

const nodeTypes = {
  diamond: DiamondNode,
};

// const initialNodes = [
//   {
//     id: '1',
//     type: 'input',
//     data: {
//       label: 'Initiate Case'
//     },
//     position: { x: 0, y: 0 }
//   }
// ];

const initialNodes = [
  { id: Math.floor(Math.random() * 1000).toString(), 
    type: 'input', data: { label: 'Starting Node' }, 
    position: { x: 250, y: 0 }, 
    style: {
      border: '1px solid darkgreen',
      backgroundColor: 'mintcream',
    },    
    draggable: true },
  { id: Math.floor(Math.random() * 1000).toString(), 
    type: 'output', 
    data: { label: 'Terminal Node' }, 
    position: { x: 250, y: 300 }, 
    style: {
      border: '1px solid darkred',
      backgroundColor: 'seashell',
    },    
    draggable: true },
];

const initialEdges = [
  //{ id: 'e1-2', source: '1', target: '2', label: 'this is an edge label' },
  //{ id: 'e1-3', source: '1', target: '3', animated: true },
];


const onInit = reactFlowInstance =>
  {const temp = reactFlowInstance;}
  //console.log('flow loaded:', reactFlowInstance);

const WorkflowDesigner = () => {
  const { workflowId } = useParams();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [edgeModalShow, setEdgeModalShow] = useState(false);
  const [edgeModalData, setEdgeModalData] = useState({});
  const [nodeModalShow, setNodeModalShow] = useState(false);
  const [nodeModalData, setNodeModalData] = useState({});
  const [showDecisionModal, setShowDecisionModal] = useState(false);

  const [showModal, setShowModal] = useState(false); //to display modal
  const [drawerOpen, setDrawerOpen] = useState(false); //to display menu drawer
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [workflowDetails, setWorkflowDetails] = useState();
  const [currentNode, setCurrentNode] = useState(null);
  const [showModalProcess, setShowModalProcess] = useState(false);
  const [showModalDecision, setShowModalDecision] = useState(false);

  const [actionName, setActionName] = useState();
  const [slaId, setSlaId] = useState();
  const [formDefinitionId, setFormDefinitionId] = useState();
  const [preAssignedUserId, setPreAssignedUserId] = useState();
  const [preAssignedUserTypeId, setPreAssignedUserTypeId] = useState();

  const [slaMasterList, setSLAMasterList] = useState([]);
  const [formMasterList, setFormMasterList] = useState([]);
  const [WfTaskUsersMasterList, setWfTaskUsersMasterList] = useState([]);
  const [WfTaskUserTypesMasterList, setWfTaskUserTypesMasterList] = useState([]);
  const [WfNotificationTemplateList, setWfNotificationTemplateList] = useState([]);
  const [WfActionList, setWfActionList] = useState([]);

  const [loadData,setLoadData] = useState(false);

  //const [workflowId, setWorkflowId] = useState(workflowIdParam);
  // Populate this from the wf_actions table
  // const [WfActionList, setActionList] = useState([
  //   { value: 'submit_form', label: 'submit_form' },
  //   { value: 'process_reimbursement', label: 'process_reimbursement' },
  //   { value: 'send_notification_all', label: 'send_notification_all' },
  //   { value: 'wfa_send_email', label: 'wfa_send_email' }
  // ]);
  const getWorkflowData = async () => {
    try {
      const response = await AuthApi.get(
        `workflowapp/api/getWorkflow/${workflowId}/`
      );
      if (response.status === 200) {
        response.data.workflow_json
          ? setNodes(response.data.workflow_json.nodes)
          : setNodes(initialNodes);
        response.data.workflow_json
          ? setEdges(response.data.workflow_json.edges)
          : setEdges(initialEdges);
        setWorkflowDetails(response.data);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }
  };

  const getMasterLists = async () => {
    try {
      const response = await AuthApi.get(
        `workflowapp/api/getWfDesignerMasterLists/`
      );
      if (response.status === 200) {
        setSLAMasterList(response.data.slas);
        setFormMasterList(response.data.forms);
        setWfTaskUsersMasterList(response.data.task_users);
        setWfTaskUserTypesMasterList(response.data.task_user_types);
        setWfNotificationTemplateList(response.data.notification_templates);
        setWfActionList(response.data.actions);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }
  };

  useEffect(() => {
    getWorkflowData();
    getMasterLists();
  }, [workflowId]);

  useEffect(() => {
    if(loadData){
      getWorkflowData();
      getMasterLists();
      setLoadData(false);
    }
  }, [loadData]);

  useEffect(() => {
    //when the selectedNode is changing, update the value so that correct values are displayed in the modal form
    if (selectedNode) {
      setActionName(selectedNode.data.action_name);
      setSlaId(selectedNode.data.sla_id);
      setFormDefinitionId(selectedNode.data.form_definition_id);
      setPreAssignedUserId(selectedNode.data.pre_assigned_user_id);
      setPreAssignedUserTypeId(selectedNode.data.pre_assigned_user_type_id);
    }
  }, [selectedNode]); // Dependency array to ensure updates if selectedNode changes

  const handleSubmit = event => {
    event.preventDefault();

    // var workflow = [
    //   {
    //     workflow_id: null,
    //     name: 'first workflow',
    //     description: 'first workflow',
    //     workflow_case_code: 'wdf232',
    //     is_active: true
    //   }
    // ];

    const saveWorkflow = async () => {
      try {
        const response = await AuthApi.post(
          '/workflowapp/api/saveWorkflowDesignerSteps/', // API Endpoint
          { workflow: workflowDetails, nodes, edges }, //POST Parameters
          { headers: { 'Content-type': 'Application/json' } } //request headers (Authorization etc)
        );
        //setBlogList(response.data.blogList);
        setLoadData(true);
        toast.success('Workflow Saved', { autoClose: 1000 });
        if (!workflowId && response.data.workflow_id)
          window.location.href =
            'crm-admin/workflow-designer/' + response.data.workflow_id + '/';
        console.log(response.data);
      } catch (error) {
        toast.error('Workflow not saved, please try later', {
          autoClose: 1000
        });
        console.error('Failed', error);
      }
    };

    saveWorkflow();
  }; //

  // - Before chaging for workflow-group - start
  // const onConnect = useCallback(
  //   params => setEdges(eds => addEdge(params, eds)),
  //   []
  // );
  // - Before changing for workflow-group - end
  
  const edgeUpdateSuccessful = useRef(true);

  const onConnect = (params) => {

      console.log(params);

      const { source, target, sourceHandle, targetHandle } = params;
      const sourceNode = nodes.find(node => node.id === source);
      const targetNode = nodes.find(node => node.id === target);
  
      let newEdge = {
        id: `${source}-${target}`,
        source,
        sourceHandle,
        target,
        targetHandle,
        animated: false,
        markerEnd: 'arrowhead', // Add arrowhead marker
      };
      console.log(sourceNode)
      if (sourceNode?.type === 'diamond') {
        newEdge = {
          ...newEdge,
          label: "Edit Decision",
          style: { stroke: 'goldenrod' },
          data: { label: 'Decision to Process' },
        };
      } else {
          newEdge = {
            ...newEdge,
            label: "Delete",
            data: { label: 'Normal Edge' },  
        };
      }
  
      setEdges((prevEdges) => [...prevEdges, newEdge]);

      setEdges((eds) => {
        const updatedEdges = addEdge(params, eds);
        const nodeOutgoingConnections = {};

        updatedEdges.forEach((edge) => {
          nodeOutgoingConnections[edge.source] = (nodeOutgoingConnections[edge.source] || 0) + 1;
        });
        
        console.log(nodeOutgoingConnections);

        setNodes((nds) => 
          nds.map((node) => {
            if (nodeOutgoingConnections[node.id] > 1) {
              return {
                ...node,
                'workflow-group': true,
                data: {
                  ...node.data,
                  'workflow-group': true,
                },
              };
            }
            return node;
          })
        );

        const updatedNodes = updatedEdges.reduce((acc, edge) => {
          const targetNode = nodes.find(node => node.id === edge.target);
          console.log(targetNode);
          if (nodeOutgoingConnections[edge.source] > 1 && targetNode) {
            acc.push({
              ...targetNode,
              data: {
                ...targetNode.data,
                child_node: true,
                parent_node_id: edge.source,
              },
            });
          }
          return acc;
        }, []);
        


        setNodes(nds =>
          nds.map(node =>
            updatedNodes.find(updatedNode => updatedNode.id === node.id) || node
          )
        );
        console.log("updated nodes: "  + updatedNodes);
        return updatedEdges;
      });
    };
  
  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      setEdges((eds) => {
        const remainingEdges = eds.filter(
          (edge) => !deletedEdges.some((deletedEdge) => deletedEdge.id === edge.id)
        );
        const nodeOutgoingConnections = {};
  
        remainingEdges.forEach((edge) => {
          nodeOutgoingConnections[edge.source] = (nodeOutgoingConnections[edge.source] || 0) + 1;
        });
  
        setNodes((nds) =>
          nds.map((node) => {
            if (nodeOutgoingConnections[node.id] > 1) {
              return {
                ...node,
                data: {
                  ...node.data,
                  'workflow-group': true,
                },
              };
            } else if (node.data['workflow-group']) {
              const updatedData = { ...node.data };
              delete updatedData['workflow-group'];
              return {
                ...node,
                data: updatedData,
              };
            }
            return node;
          })
        );
  
        return remainingEdges;
      });
    },
    [setNodes, setEdges]
  );

  const onEdgeUpdateStart = (event, edge) => {
    console.log('Edge update start', edge);
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    //setEdges((els) => updateEdge(oldEdge, newConnection, els));
    console.log('Edge update start');
  };

  const onEdgeUpdateEnd = useCallback(
    (_, edge) => {
      // if (!edgeUpdateSuccessful.current) {
      //   setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      // }

      edgeUpdateSuccessful.current = true;

      const nodeOutgoingConnections = {};
      edges.forEach((edge) => {
        nodeOutgoingConnections[edge.source] = (nodeOutgoingConnections[edge.source] || 0) + 1;
      });

      setNodes((nds) =>
        nds.map((node) => {
          if (nodeOutgoingConnections[node.id] > 1) {
            return {
              ...node,
              data: {
                ...node.data,
                'workflow-group': true,
              },
            };
          } else if (node.data['workflow-group']) {
            const updatedData = { ...node.data };
            delete updatedData['workflow-group'];
            return {
              ...node,
              data: updatedData,
            };
          }
          return node;
        })
      );
    },
    [edges, setNodes]
  );



  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map(edge => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find(node => node.type === 'custom').data.selects[
        edge.sourceHandle
      ];
      edge.type = edgeType;
    }

    return edge;
  });

  //This is for displaying popup - Start
  //   const handleNodeClick = (event, node) => {
  //     setCurrentNode(node);
  //     setShowModal(true);
  //   };
  //This is for displaying popup - End

  //  This is for displaying menu drawer - Start
  const handleNodeClick = (node) => {
    console.log(node.type);
    setSelectedNode(node);
    //setDrawerOpen(true);
    if(node.type == "diamond")
      setShowDecisionModal(true);
    else
      setNodeModalShow(true);
  };
  //This is for displaying popup - End
  const handleEdgeClick = (edge) => {
    console.log(edge);
    const sourceNode = nodes.find(node => node.id === edge.source);
    const targetNode = nodes.find(node => node.id === edge.target);   
    setSelectedNode(sourceNode); 
    setSelectedEdge(edge);
    setEdgeModalData(edge);
    setEdgeModalShow(true);
  };

  const handleModalClose = () => {setShowModalDecision(false);setShowModalProcess(false);}

  const updateProcessNode = ( nodeId, updatedData) => {
    console.log(updatedData)
    const updatedNode = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        label: updatedData.step_name,
        step_name: updatedData.step_name,
        action_name: updatedData.action_name,
        sla_id: updatedData.sla_id,
        form_definition_id: updatedData.form_definition_id,
        pre_assigned_user_id: updatedData.pre_assigned_user_id,
        pre_assigned_user_type_id: updatedData.pre_assigned_user_type_id,
        notification_template: updatedData.notification_template,
      }
    };
    setNodes(nds => nds.map(n => (n.id === selectedNode.id ? updatedNode : n)));
    setShowModalProcess(false);
  };

  // const updateDecisionNode = (nodeId, updatedData) => {
  //   const newNodes = nodes.map(node => {
  //     if (node.id === nodeId) {
  //       return { ...node, data: { ...node.data, ...updatedData } };
  //     }
  //     return node;
  //   });
  //   setNodes(newNodes);
  //   setShowModalDecision(false);
  // };  
  const updateDecisionNode = ( nodeId, updatedData) => {

    console.log("In Update Decisions...... start");
    console.log(updatedData);
    console.log(selectedNode);
    console.log("In Update Decisions...... end");
    const updatedNode = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        // label: updatedData.step_name,
        // step_name: updatedData.step_name,
        // action_name: updatedData.action_name,
        // condition_field: updatedData.condition_field,
        // condition_operator: updatedData.condition_operator,
        // condition_value: updatedData.condition_value,
        // condition_target_field: updatedData.condition_target_field,        
        label: updatedData.step_name,
        step_name: updatedData.step_name,
        action_name:  updatedData.action_name,
        // [updatedData.edgeId]: {
        //   approved_conditions: updatedData.approved_conditions
        // }
        ...(updatedData.edgeId ? { [updatedData.edgeId]: { approved_conditions: updatedData.approved_conditions } } : {})
        //rejected_conditions: updatedData.rejected_conditions        
      }
    };
    setNodes(nds => nds.map(n => (n.id === selectedNode.id ? updatedNode : n)));

    //foreach(updatedData.approved_conditions)
    // updatedData.approved_conditions.map((conditionValue,i) => {
    //   //console.log(conditionValue.nextStep.value)
    //   addNewEdge(nodeId, conditionValue.nextStep.value);
    // });
  };

  const updateDecisionEdge = ( edgeId, updatedData) => {


    console.log("In Update Decision edge...... start");
    console.log(updatedData);
    console.log(edgeId);
    console.log("In Update Decision edge...... end");

    const updatedEdge = {
      ...selectedEdge,
      data: {
        ...selectedEdge.data,
        // label: updatedData.step_name,
        // step_name: updatedData.step_name,
        // action_name: updatedData.action_name,
        // condition_field: updatedData.condition_field,
        // condition_operator: updatedData.condition_operator,
        // condition_value: updatedData.condition_value,
        // condition_target_field: updatedData.condition_target_field,        
        label: updatedData.step_name,
        step_name: updatedData.step_name,
        action_name:  updatedData.action_name,
        approved_conditions: updatedData.approved_conditions,
        //rejected_conditions: updatedData.rejected_conditions        
      }
    };
    setEdges(eds => eds.map(e => (e.id === edgeId ? updatedEdge : e)));

  };


  function addNewEdge  (source,target) {
    console.log(source+'-'+target);
    
    const newEdge = {
      id: source+'-'+target,
      source: source,
      target: target,
      animated: false, // You can add other properties as needed
    };

    setEdges((eds) => addEdge(newEdge, eds));
  };

  const addNewNode = () => {
    const newNode = { id: Math.floor(Math.random() * 1000).toString(), type: 'default', data: { label: 'Process Node' }, position: { x: 250, y: 400 }, draggable: true };
    setNodes((nds) => [...nds, newNode]);
  };
  const clearAll = () => {
    setNodes([]);
    setEdges([]);
  };

  // const handleFormSubmit = event => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);

  //   const updatedNode = {
  //     ...selectedNode,
  //     data: {
  //       ...selectedNode.data,
  //       label: formData.get('step_name'),
  //       step_name: formData.get('step_name'),
  //       action_name: actionName,
  //       sla_id: slaId,
  //       form_definition_id: formDefinitionId,
  //       pre_assigned_user_id: preAssignedUserId,
  //       pre_assigned_user_type_id: preAssignedUserTypeId
  //     }
  //   };
  //   setNodes(nds => nds.map(n => (n.id === selectedNode.id ? updatedNode : n)));
  //   handleModalClose();
  // };

  const addNode = () => {
    const newNode = {
      id: (nodes.length + 1).toString(), // Generate unique ID for new node
      type: 'default', // Set the type of your new node
      data: { label: `New Node ${nodes.length + 1}` }, // Provide any data you want to associate with the new node
      position: { x: 0, y: 0 } // Set the initial position of the new node
    };

    setNodes(prevNodes => [...prevNodes, newNode]); // Add the new node to the nodes array
  };

   const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = event.target.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      const pixelNodeType = type === 'default' ? 'Process' : type === 'diamond' ? 'Decision' : type === 'input' ? 'Starting step' : type === 'output' ? 'Terminal step' : `${type.charAt(0).toUpperCase() + type.slice(1)}`;

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const newNode = {
        id: `${type}-${+new Date()}`,
        type,
        position,
        //data: { label: `${type.charAt(0).toUpperCase() + type.slice(1)}` },
        data: { label: pixelNodeType },
        style: {
          border: type === 'input' ? '1px solid darkgreen' : type === 'output' ? '1px solid darkred' : type === 'default' ? '1px solid darkblue' : undefined,
          backgroundColor: type === 'input' ? 'mintcream' : type === 'output' ? 'seashell' : type === 'default' ? 'aliceblue'  : undefined,
        },
        draggable: true,
        node_type: type === 'diamond' ? 'decision' : 'process',
      };

      setNodes((nds) => nds.concat(newNode));
      setSelectedNode(newNode);
      console.log("New Node:");
      console.log(newNode);

      type === 'diamond' ? setShowDecisionModal(true) : setNodeModalShow(true);
    },
    [setNodes]
  );

  const handleDrag = event => {
    setDrawerOpen(false); //hide the drawer menu when the node is being dragged
  };

  const onDragOver = event => {
    event.preventDefault();
  };

  /***************Drawer Functions start */
  const handleCloseDrawer = () => setDrawerOpen(false);

  const handleEdit = () => {
    // Define edit logic or open another modal for detailed edit
    //setShowModal(true);

    
    if (selectedNode.node_type === 'process') {
      setShowModalProcess(true);
    } else if (selectedNode.node_type === 'decision') {
      setShowModalDecision(true);
    }

  };

  /************* FOLLWING IS THE WORKING handleNodeDelete before implementing the edge data deletion - revert to this */
  /*************START */
  /*
  const handleNodeDelete = (nodeId) => {
    console.log("Deleting node : " + nodeId);

    setNodes(prevNodes => prevNodes.filter(node => node.id !== nodeId));

    // Also delete any edges connected to this node
    setEdges(prevEdges => prevEdges.filter(edge => edge.source !== nodeId && edge.target !== nodeId));

    setNodeModalShow(false); // Close modal after deletion
  };
  */
  /********** END */

  const handleNodeDelete = (nodeId) => {
    console.log("Deleting node: " + nodeId);
  
    // Get edges connected to the node
    setEdges(prevEdges => {
      const connectedEdges = prevEdges.filter(edge => edge.source === nodeId || edge.target === nodeId);
      
      // Delete edges connected to the node
      const updatedEdges = prevEdges.filter(edge => edge.source !== nodeId && edge.target !== nodeId);
  
      // Find the parent nodes and remove the edge ID from their data
      setNodes(prevNodes => prevNodes.map(node => {
        const updatedData = { ...node.data };
        
        connectedEdges.forEach(edge => {
          if (updatedData[edge.id]) {
            delete updatedData[edge.id];
          }
        });
        
        return {
          ...node,
          data: updatedData
        };
      }));
  
      return updatedEdges;
    });
  
    // Delete the node
    setNodes(prevNodes => prevNodes.filter(node => node.id !== nodeId));
  
    setNodeModalShow(false); // Close modal after deletion
  };

  const handleEdgeDelete = (edgeId) => {
    setEdges(prevEdges => prevEdges.filter(edge => edge.id !== edgeId));

    // Find the parent node and remove the edge ID from its data
    setNodes(prevNodes => prevNodes.map(node => {
      const updatedData = { ...node.data };
      console.log(updatedData);

      // Find and delete the key with the edgeId in the node's data
      for (const key in updatedData) {
          if (key === edgeId) {
              delete updatedData[key];
          }
      }
      console.log(node);
      return {
          ...node,
          data: updatedData
      };
    }));

    setEdgeModalShow(false); // Close modal after deletion
  };

  const handleDelete = () => {
    const res = window.confirm('Current node will be deleted, are you sure?');

    if (res) {
      setNodes(nds => nds.filter(n => n.id !== selectedNode.id));
      handleCloseDrawer();
    }
  };

  const handleSave = () => {
    // Save logic here
    toast.success('Data Saved');
  };

  const handleClose = () => {

    handleCloseDrawer();
  };

  /***************Drawer Functions end */

  return (
    <>
{/* <div className="h2 text-center">{workflowDetails?.name}</div> */}
<ReactFlowProvider>
      <div className="container" style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
        <div style={{ height: 500 }} onDrop={onDrop} onDragOver={onDragOver}>
          <ReactFlow 
            nodes={nodes} 
            edges={edges} 
            onNodesChange={onNodesChange} 
            onEdgesChange={onEdgesChange}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            onEdgeClick={(event, edge) => handleEdgeClick(edge)}
            onNodeClick={(event, node) => handleNodeClick(node)}
          >
            <Background />
            <Controls />
          </ReactFlow>
        </div>
        <div className="mt-3 text-bg-dark p-3">
          <button className="btn btn-secondary mx-2" onClick={handleSubmit}>Save Workflow</button>
          {/* <button className="btn btn-secondary mx-2" onClick={addNewNode}>Add Node</button> */}
          <button className="btn btn-info mx-2" onClick={clearAll}>Clear All</button>
          <div
            className="btn btn-primary mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'default')}
            draggable
          >
            Process Node
          </div>
          <div
            className="btn btn-warning mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'diamond')}
            draggable
          >
            Decision Node
          </div>
          {/* <div
            className="btn btn-success mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'input')}
            draggable
          >
            Starting Node
          </div>
          <div
            className="btn btn-danger mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'output')}
            draggable
          >
            Terminal Node
          </div> */}
        </div>
      </div>

      {/* Edge Modal -*/}
      {edgeModalShow &&
      <EdgeModal 
        edge={selectedEdge} 
        edgeModalShow={edgeModalShow} 
        setEdgeModalShow={setEdgeModalShow} 
        onDeleteEdge={handleEdgeDelete} 

        node={nodeModalData} 
        setShowDecisionModal={setShowDecisionModal} 

        selectedNode={selectedNode}
        updateDecisionNode={updateDecisionNode}
        updateDecisionEdge={updateDecisionEdge}
        workflowId={workflowId}
        nodes={nodes}     

      />
      }
      {/* Node Modal */}
      {nodeModalShow && 
      <NodeModal 
        node={nodeModalData} 
        showModal={nodeModalShow} 
        setShowModal={setNodeModalShow} 
        onDeleteNode={handleNodeDelete} 


        handleClose={() => setShowModalProcess(false)}
        selectedNode={selectedNode}
        updateNodeData={updateProcessNode}
        WfActionList={WfActionList}
        slaMasterList={slaMasterList}
        formMasterList={formMasterList}
        WfTaskUsersMasterList={WfTaskUsersMasterList}
        WfTaskUserTypesMasterList={WfTaskUserTypesMasterList}   
        WfNotificationTemplateList={WfNotificationTemplateList}
        updateProcessNode={updateProcessNode}          
        
        />
      }
      {showDecisionModal && 
      <DecisionModal 
        node={nodeModalData} 
        showDecisionModal={showDecisionModal} 
        setShowDecisionModal={setShowDecisionModal} 
        onDeleteNode={handleNodeDelete} 

        selectedNode={selectedNode}
        updateDecisionNode={updateDecisionNode}
        workflowId={workflowId}
        nodes={nodes}         
        
        />
      }

      {/* <div>
      <ModalProcess
        showModal={showModalProcess}
        handleClose={() => setShowModalProcess(false)}
        selectedNode={selectedNode}
        updateNodeData={updateProcessNode}
        WfActionList={WfActionList}
        slaMasterList={slaMasterList}
        formMasterList={formMasterList}
        WfTaskUsersMasterList={WfTaskUsersMasterList}
        WfTaskUserTypesMasterList={WfTaskUserTypesMasterList}   
        WfNotificationTemplateList={WfNotificationTemplateList}
        updateProcessNode={updateProcessNode}     
      />

      <ModalDecision
        showModal={showModalDecision}
        handleClose={() => setShowModalDecision(false)}
        selectedNode={selectedNode}
        updateDecisionNode={updateDecisionNode}
        workflowId={workflowId}
        nodes={nodes}
      />

        {drawerOpen && (
          <NodeMenu
            node={selectedNode}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onSave={handleSave}
            onClose={handleClose}
          />
        )}
      </div> */}



    </ReactFlowProvider>
      <ToastContainer />

    </>
  );
};

export default WorkflowDesigner;
