
import React, { useState, useEffect } from 'react';
import {Modal, Form, Row, Button, Col } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { ToastContainer, toast } from 'react-toastify';
import { Api,AuthApi } from 'Api';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';

const DecisionModal = (props) => {

  const {
    node,										
    setShowDecisionModal,				
    onDeleteNode,				
  
    showDecisionModal,
    selectedNode,
    updateNodeData,
    updateDecisionNode,
    nodes
  } = props;

  // Local state to manage form inputs
  const [stepName, setStepName] = useState('');
  const [actionName, setActionName] = useState('');
  const [workflowSteps,setWorkflowSteps] = useState();
  const [stepNameMessage, setStepNameMessage] = useState("");

  const initialCondition = {
    conditionField: null,
    conditionOperator: null,
    conditionValue: null,
    conditionTargetField: null,
    nextStep:null,
  };

  useEffect(() => {
    //getMasterLists();
    if (selectedNode) {
      setStepName(selectedNode.data.step_name);
      setActionName(selectedNode.data.action_name);
    }
  }, [selectedNode]);

  const getMasterLists = async () => {
    try {
      const response = await AuthApi.get(
        //`workflowapp/api/getFormFields/` + workflowId
        `workflowapp/api/getAllFormFields/`
      );
      if (response.status === 200) {
        setFormFieldList(response.data.formFieldList);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }

    setWorkflowSteps(getWorkflowSteps());

  };

  const handleFormSubmit = event => {
    event.preventDefault();
    const updatedData = {
      label: stepName,
      step_name: stepName,
      action_name: actionName,
    };

    updateDecisionNode(selectedNode.id, updatedData);

    console.log(updatedData);

    //handleClose();
  };

  const getWorkflowSteps = () => {
    return nodes.map((node) => ({
      value: node.id,
      label: node.data.label || node.data.step_name,
    }));
  };

  const handleClose = (e) => {
    e.preventDefault();
    if(!stepName) {
      setStepNameMessage("Step Name is mandatory, Add step name or delete the node");
      return;
    }
    setStepNameMessage("");
    setShowDecisionModal(false);
  };

  const handleDelete = () => {
    onDeleteNode(selectedNode.id);
    setShowDecisionModal(false); // Close modal after deletion (adjust as per your logic)
  };

  return (
    <div className={`modal fade ${showDecisionModal ? 'show d-block' : ''}`} size="lg" tabIndex="-1" role="dialog" style={{ display: showDecisionModal ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-bg-secondary p-3">
            <h5 className="modal-title text-white">Decision Step</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <Form onSubmit={handleFormSubmit}>
          <div className="modal-body">
            {/* <p>Node ID: {node.id}</p>
            <p>Type: {node.type}</p>
            <p>Label: {node.data?.label}</p> */}
            {/* Add more properties as needed */}
            
            <Row>
            <Form.Group className="mb-3 col-12" controlId="step_name">
              <Form.Label>Step Name</Form.Label>
              <Form.Control
                type="text"
                name="step_name"
                onChange={e => setStepName(e.target.value)}
                value={stepName}
              />
              {stepNameMessage && (
                <Form.Text className="text-danger">{stepNameMessage}</Form.Text>
              )}                  
            </Form.Group>
          </Row>
          </div>
          <div className="modal-footer d-flex justify-content-between">
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete Node</button>
              <div>
                <Button variant="success" className="me-2" type="submit">Save Changes</Button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
              </div>
          </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DecisionModal;
