import React, { useState, useCallback } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  SmoothStepEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomEdge from './CustomEdge';
import DiamondNode from './DiamondNode';
import NodeModal from './NodeModal';
import EdgeModal from './EdgeModal'; 

const edgeTypes = {
  default: SmoothStepEdge,
  custom: CustomEdge,
};

const nodeTypes = {
  diamond: DiamondNode,
};

const initialNodes = [
  { id: 'a', type: 'input', data: { label: 'Node A' }, position: { x: 250, y: 0 }, draggable: true },
  { id: 'b', type: 'diamond', data: { label: 'Node B' }, position: { x: 250, y: 100 }, draggable: true },
  { id: 'c', type: 'default', data: { label: 'Node C' }, position: { x: 250, y: 300 }, draggable: true },
];

const initialEdges = [];

const CustomMarker = () => (
  <svg width="0" height="0">
    <defs>
      <marker
        id="arrowhead"
        viewBox="0 0 10 10"
        refX="8"
        refY="5"
        markerWidth="6"
        markerHeight="6"
        orient="auto"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="#000" />
      </marker>
    </defs>
  </svg>
);


const Workflow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [edgeModalShow, setEdgeModalShow] = useState(false);
  const [edgeModalData, setEdgeModalData] = useState({});
  const [nodeModalShow, setNodeModalShow] = useState(false);
  const [nodeModalData, setNodeModalData] = useState({});

  const onConnect = (params) => {
    const { source, target, sourceHandle, targetHandle } = params;
    const sourceNode = nodes.find(node => node.id === source);
    const targetNode = nodes.find(node => node.id === target);

    let newEdge = {
      id: `${source}-${target}`,
      source,
      sourceHandle,
      target,
      targetHandle,
      animated: false,
      markerEnd: 'arrowhead', // Add arrowhead marker
    };

    if (sourceNode?.type === 'diamond') {
      newEdge = {
        ...newEdge,
        label: "Edit Decision",
        style: { stroke: 'goldenrod' },
        data: { label: 'Decision to Process' },
      };
    } else {
        newEdge = {
          ...newEdge,
          label: "Delete",
          data: { label: 'Normal Edge' },  
      };
    }

    setEdges((prevEdges) => [...prevEdges, newEdge]);
  };

  const handleEdgeClick = (edge) => {
    setEdgeModalData(edge);
    setEdgeModalShow(true);
  };

  const handleNodeClick = (node) => {
    setNodeModalData(node);
    setNodeModalShow(true);
  };

  const onEdgeUpdateStart = (event, edge) => {
    console.log('Edge update start', edge);
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  };

  const onEdgeUpdateEnd = (event, edge) => {
    console.log('Edge update end', edge);
  };

  const addNewEdge = () => {
    const newEdge = { id: '123', source: 'a', target: 'b', animated: false, type: 'custom', data: { label: 'Edge A to B' }, markerEnd: 'url(#arrowhead)' };
    setEdges((eds) => addEdge(newEdge, eds));
  };

  const addNewNode = () => {
    const newNode = { id: Math.floor(Math.random() * 1000).toString(), type: 'default', data: { label: 'Process Node' }, position: { x: 250, y: 400 }, draggable: true };
    setNodes((nds) => [...nds, newNode]);
  };

  const handleNodeDelete = (nodeId) => {
    setNodes(prevNodes => prevNodes.filter(node => node.id !== nodeId));

    // Also delete any edges connected to this node
    setEdges(prevEdges => prevEdges.filter(edge => edge.source !== nodeId && edge.target !== nodeId));

    setNodeModalShow(false); // Close modal after deletion
  };

  const handleEdgeDelete = (edgeId) => {
    setEdges(prevEdges => prevEdges.filter(edge => edge.id !== edgeId));
    setEdgeModalShow(false); // Close modal after deletion
  };

  const clearAll = () => {
    setNodes([]);
    setEdges([]);
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = event.target.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      const pixelNodeType = type === 'default' ? 'Process' : type === 'diamond' ? 'Decision' : type === 'input' ? 'Starting step' : type === 'output' ? 'Terminal step' : `${type.charAt(0).toUpperCase() + type.slice(1)}`;

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const newNode = {
        id: `${type}-${+new Date()}`,
        type,
        position,
        //data: { label: `${type.charAt(0).toUpperCase() + type.slice(1)}` },
        data: { label: pixelNodeType },
        style: {
          border: type === 'input' ? '1px solid darkgreen' : type === 'output' ? '1px solid darkred' : type === 'default' ? '1px solid darkblue' : undefined,
          backgroundColor: type === 'input' ? 'mintcream' : type === 'output' ? 'seashell' : type === 'default' ? 'aliceblue'  : undefined,
        },
        draggable: true,
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  return (
    <ReactFlowProvider>
      <div className="container mt-5" style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
        <div style={{ height: 500 }} onDrop={onDrop} onDragOver={onDragOver}>
          <ReactFlow 
            nodes={nodes} 
            edges={edges} 
            onNodesChange={onNodesChange} 
            onEdgesChange={onEdgesChange}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            onEdgeClick={(event, edge) => handleEdgeClick(edge)}
            onNodeClick={(event, node) => handleNodeClick(node)}
          >
            <Background />
            <Controls />
          </ReactFlow>
        </div>
        <div className="mt-3 text-bg-dark p-3">
          <button className="btn btn-secondary mx-2" onClick={addNewEdge}>Add Edge from A to B</button>
          <button className="btn btn-secondary mx-2" onClick={addNewNode}>Add Node</button>
          <button className="btn btn-info mx-2" onClick={clearAll}>Clear All</button>
          <div
            className="btn btn-primary mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'default')}
            draggable
          >
            Process Node
          </div>
          <div
            className="btn btn-warning mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'diamond')}
            draggable
          >
            Decision Node
          </div>
          <div
            className="btn btn-success mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'input')}
            draggable
          >
            Starting Node
          </div>
          <div
            className="btn btn-danger mx-2"
            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'output')}
            draggable
          >
            Terminal Node
          </div>
        </div>
      </div>

      {/* Edge Modal */}
      <EdgeModal edge={edgeModalData} showModal={edgeModalShow} setShowModal={setEdgeModalShow} onDeleteEdge={handleEdgeDelete} />

      {/* Node Modal */}
      <NodeModal node={nodeModalData} showModal={nodeModalShow} setShowModal={setNodeModalShow} onDeleteNode={handleNodeDelete} />

    </ReactFlowProvider>
  );
};

export default Workflow;
