
import React, { useState, useEffect } from 'react';
import {Modal, Form, Row, Button, Col } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { ToastContainer, toast } from 'react-toastify';
import { Api, AuthApi } from 'Api';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';
import { FaInfoCircle } from "react-icons/fa";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const EdgeModal = (props) => {
  const {
    node,								
    edge,		
    setEdgeModalShow,
    edgeModalShow,				
    onDeleteEdge,				
  
    selectedNode,
    updateDecisionNode,
    nodes
  } = props;

  // Local state to manage form inputs
  const [stepName, setStepName] = useState('');
  const [actionName, setActionName] = useState('');
  const [workflowSteps,setWorkflowSteps] = useState();

  console.log(nodes);

  const initialCondition = {
    edgeId:null,
    conditionField: null,
    conditionOperator: null,
    conditionValue: null,
    conditionTargetField: null,
    nextStep:null,
  };

  const [approvedConditions, setApprovedConditions] = useState([
    { conditionField: '', conditionOperator: '', conditionValue: 'on', conditionTargetField: '', isTargetFieldDropdown: true,nextStep:null,edgeId:edge.id }
  ]);
  const [rejectedConditions, setRejectedConditions] = useState([
    { conditionField: '', conditionOperator: '', conditionValue: 'off', conditionTargetField: '', isTargetFieldDropdown: true,edgeId:'' }
  ]);

  const operatorOptions = [
    { value: '==', label: 'equal to' },
    { value: '<>', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '<', label: 'less than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<=', label: 'less than or equal to' },
    { value: 'LIKE', label: 'like' },
    { value: 'NOT LIKE', label: 'not like' }
  ];

  const [formFieldList, setFormFieldList] = useState([]);
  const [isTargetFieldDropdown, setIsTargetFieldDropdown] = useState(true);

  // useEffect(() => {
  //   const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  //   const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
  // }, [edgeModalShow]);

  useEffect(() => {
    const fetchData = async () => {
      await getMasterLists();
    };
    fetchData();

    if (edge.id !== false) {
      setStepName(selectedNode.data.step_name);
      setActionName(selectedNode.data.action_name);
      console.log("setting up initial values");
      const matchingConditionsVar = selectedNode.data[edge.id]?.approved_conditions || [initialCondition];
      if(matchingConditionsVar.length > 0)
        setApprovedConditions(
          matchingConditionsVar
        );
      else
        setApprovedConditions(
          [initialCondition]
        );
    }
  }, [edge]);

  const getMasterLists = async () => {
    try {
      const response = await AuthApi.get(
        //`workflowapp/api/getFormFields/` + workflowId
        `workflowapp/api/getAllFormFields/`
      );
      if (response.status === 200) {
        setFormFieldList(response.data.formFieldList);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }

    setWorkflowSteps(getWorkflowSteps());

  };

  const handleFormSubmit = event => {
    event.preventDefault();

    console.log(edge.id);

    const updatedData = {
      label: stepName,
      step_name: stepName,
      action_name: actionName,
      edgeId: edge.id,
      approved_conditions: approvedConditions,
      //rejected_conditions: rejectedConditions
    };

    updateDecisionNode(selectedNode.id, updatedData);

    console.log(updatedData);
    setEdgeModalShow(false);
    //handleClose();
  };

  const handleConditionChange = (index, type, field, value) => {
    console.log("approvedCondition in handleconditionChange....")
    console.log(approvedConditions);
    const updatedConditions = approvedConditions.map((condition, i) => 
      i === index ? { ...condition, edgeId:edge.id,nextStep:workflowSteps?.find(option => option.value === edge.target), [field]: value } : condition
    );
    console.log("updatedConditions in handleconditionChange....")
    console.log(updatedConditions);
    setApprovedConditions(updatedConditions);
    
  };
  const getWorkflowSteps = () => {
    return nodes.map((node) => ({
      value: node.id,
      label: node.data.label || node.data.step_name,
    }));
  };

  const handleDelete = () => {
    onDeleteEdge(edge.id);
    setEdgeModalShow(false);
  };

  return (
    <div className={`modal fade ${edgeModalShow ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: edgeModalShow ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-bg-secondary p-3">
            <h5 className="modal-title text-white">Edge Information</h5>
            <button type="button" className="btn-close" onClick={() => setEdgeModalShow(false)} aria-label="Close"></button>
          </div>
          {/* <div className="modal-body">
          <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-content="Right popover">
                
                <p>Edge ID: {edge.id}</p>
                <p>Source: {edge.source}</p>
                <p>Target: {edge.target}</p>
                <p>Label: {edge.data?.label}</p>
              </button> 
          </div> */}
          <Form onSubmit={handleFormSubmit}>
          <div className="modal-body">
            {/* Add more properties as needed */}
            
            <Row>
            <Form.Group className="mb-6 col-12" controlId="step_name">
              <Form.Label>Step Name:</Form.Label>
              <span className="h5 ms-2">{stepName}</span>
              <div className="small mt-1 ms-3"><em>(To Modify the step name, click on the decision diamond)</em></div>
              {/* <Form.Control
                type="text"
                name="step_name"
                onChange={e => setStepName(e.target.value)}
                value={stepName}
              /> */}
            </Form.Group>
          </Row>
          <h5>Conditions</h5>
          {console.log("In View.....")}
          {console.log(approvedConditions )}
          {approvedConditions.map((condition, index) => (
            <Row key={`approved-${index}`} className='align-items-end'>
              
              <Col md={6} className='mt-2'>
                <Form.Group>
                  <Form.Label>Condition Field</Form.Label>
                  <ReactSelect
                    placeholder="Select Condition field"
                    options={formFieldList}
                    closeMenuOnSelect={true}
                    onChange={value => handleConditionChange(index, 'approved', 'conditionField', value)}
                    value={condition.conditionField}
                    isClearable={true}
                    isSearchable={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Operator</Form.Label>
                  <ReactSelect
                    placeholder="Select Operator"
                    options={operatorOptions}
                    closeMenuOnSelect={true}
                    onChange={value => handleConditionChange(index, 'approved', 'conditionOperator', value)}
                    value={condition.conditionOperator}
                    isClearable={true}
                    isSearchable={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mt-4">
                <Form.Group>
                  <Form.Label>Target Field</Form.Label>
                  <Form.Check
                    type="switch"
                    label="Use Dropdown to select a form field"
                    checked={condition.isTargetFieldDropdown ? condition.isTargetFieldDropdown :false}
                    onChange={() => handleConditionChange(index, 'approved', 'isTargetFieldDropdown', !condition.isTargetFieldDropdown)}
                  />
                  {condition.isTargetFieldDropdown ? (
                    <ReactSelect
                      placeholder="Select Target Field"
                      options={formFieldList}
                      closeMenuOnSelect={true}
                      onChange={value => handleConditionChange(index, 'approved', 'conditionTargetField', value)}
                      value={condition.conditionTargetField}
                      isClearable={true}
                      isSearchable={true}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Enter Target Field"
                      value={condition.conditionTargetField}
                      onChange={e => handleConditionChange(index, 'approved', 'conditionTargetField', e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={12}>
              <Form.Group className="mb-3 col-12 pt-4" controlId="next_step">
              <Form.Label>Next Step</Form.Label>
              <div className="ms-3">{workflowSteps?.find(option => option.value === edge.target).label}</div>
                {/* <ReactSelect
                  placeholder="Select Next Step"
                  options={workflowSteps}
                  closeMenuOnSelect={true}
                  //onChange={value => handleConditionChange(index, 'approved', 'nextStep', value)}
                  value={workflowSteps?.find(option => option.value === edge.target)}
                  // isClearable={true}
                  // isSearchable={true}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  readonly
                /> */}
              </Form.Group>              
              </Col>
              {/* <Col md={1}>
                {approvedConditions.length > 1 && (
                  <Button
                    variant="link"
                    className="text-danger"
                    onClick={() => handleRemoveCondition(index, 'approved')}
                  >
                    <BsDashCircle size={20} />
                  </Button>
                )}
                {index === approvedConditions.length - 1 && (
                  <Button
                    variant="link"
                    onClick={() => handleAddCondition('approved')}
                  >
                    <BsPlusCircle size={20} />
                  </Button>
                )}
              </Col> */}
            </Row>
          ))}         
            {/* <Form.Group className="mb-3" controlId="form_definition_id">
                <Form.Label>Form Definition ID</Form.Label>
                <Form.Control
                  type="text"
                  name="form_definition_id"
                  defaultValue={selectedNode?.data.form_definition_id}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pre_assigned_user_id">
                <Form.Label>Pre-assigned User</Form.Label>
                <Form.Control
                  type="text"
                  name="pre_assigned_user_id"
                  defaultValue={selectedNode?.data.pre_assigned_user_id}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="pre_assigned_user_type_id"
              >
                <Form.Label>Pre-assigned User Type</Form.Label>
                <Form.Control
                  type="text"
                  name="pre_assigned_user_type_id"
                  defaultValue={selectedNode?.data.pre_assigned_user_type_id}
                />
              </Form.Group> */}



          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete Edge</button>
            <button type="submit" className="btn btn-secondary">Save</button>
          </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EdgeModal;
