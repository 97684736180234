import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const AuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, 
});

// Add trailing slash interceptor for Api
Api.interceptors.request.use(
  config => {
    if (!config.url.endsWith('/')) {
      config.url += '/';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add trailing slash and authorization token interceptor for AuthApi

AuthApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken') || Cookies.get('_pixelflow.access_token');
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (!config.url.endsWith('/')) {
      config.url += '/';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


// Add response interceptor for handling errors
AuthApi.interceptors.response.use(
  response => {
    return response; // Return the response if successful
  },
  error => {
    if (error.response && error.response.status === 401) {
      

      toast.error("Your session has expired. Please log in again.", {
        onClose: () => {
          // Redirect to login after the toast closes
          window.location.href = '/auth/login';
        },
        autoClose: 1500, // Time in ms before auto-close
      });

      // Clear the token from localStorage and cookies
      localStorage.removeItem('accessToken');
      Cookies.remove('_pixelflow.access_token');
    }
    return Promise.reject(error);
  }
);