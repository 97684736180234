import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'components/workflow-mgmt/authentication/context/AuthContext';
import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import logo from 'assets/img/logos/pixelflow_logo.svg';

const NewSignIn: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { isAuthenticated, user, logout } = authContext;

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (isAuthenticated) window.location.href = '/';
  }, [isAuthenticated]);

  return (
    <div className="container">
      <Row className="flex-center min-vh-100 py-5">
        <Col sm={10} md={8} lg={5}>
          {logo && (
              <div className="align-items-center text-center">
              <img
                src={logo}
                alt="Pixel Flow"
                className="img-block"
                width="70%"
              />
              <p className="mt-2">
                Workflow Design, Management, Execution and Anlytics
              </p>
            </div>
          )}
          <Card>
            <Card.Body className="text-center">
              {isAuthenticated ? (
                <Col>
                  Welcome, <a href="/">Click here</a> to go to workflow listing{' '}
                </Col>
              ) : (
                <Col>
                  Please{' '}
                  <Link
                    to={`${process.env.REACT_APP_API_BASE_URL}auth/login`}
                    target="_blank"
                  >
                    log in
                  </Link>{' '}
                  and refresh
                </Col>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default NewSignIn;
