import React from 'react';
import { getBezierPath, getEdgeCenter, EdgeText } from 'reactflow';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, data }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} />
      <EdgeText x={labelX} y={labelY} label={data.label} labelStyle={{ fontSize: 12 }} />
    </>
  );
};

export default CustomEdge;
