import React, { useState, useEffect } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { ToastContainer, toast } from 'react-toastify';

const NodeModal = props => {
  const {
    node,
    showModal,
    setShowModal,
    onDeleteNode,

    selectedNode,
    updateNodeData,
    WfActionList,
    slaMasterList,
    formMasterList,
    WfTaskUsersMasterList,
    WfTaskUserTypesMasterList,
    WfNotificationTemplateList,
    updateProcessNode
  } = props;

  console.log(selectedNode);
  console.log(node);
  const [stepName, setStepName] = useState();
  const [actionName, setActionName] = useState();
  const [slaId, setSlaId] = useState();
  const [formDefinitionId, setFormDefinitionId] = useState();
  const [preAssignedUserId, setPreAssignedUserId] = useState();
  const [preAssignedUserTypeId, setPreAssignedUserTypeId] = useState();
  const [notificationTemplate, setNotificationTemplate] = useState();
  const [stepNameMessage, setStepNameMessage] = useState("");

  useEffect(() => {
    if (selectedNode) {
      setStepName(selectedNode.data.step_name);
      setActionName(selectedNode.data.action_name);
      setSlaId(selectedNode.data.sla_id);
      setFormDefinitionId(selectedNode.data.form_definition_id);
      setPreAssignedUserId(selectedNode.data.pre_assigned_user_id);
      setPreAssignedUserTypeId(selectedNode.data.pre_assigned_user_type_id);
      setNotificationTemplate(selectedNode.data.notification_template);
    }
  }, [selectedNode]);

  const handleFormSubmit = event => {
    console.log('here');

    event.preventDefault();
    const updatedData = {
      label: stepName,
      step_name: stepName,
      action_name: actionName,
      sla_id: slaId,
      form_definition_id: formDefinitionId,
      pre_assigned_user_id: preAssignedUserId,
      pre_assigned_user_type_id: preAssignedUserTypeId,
      notification_template: notificationTemplate
    };

    updateProcessNode(selectedNode.id, updatedData);

    setShowModal(false);
  };
  const handleDelete = () => {
    onDeleteNode(selectedNode.id);
    setShowModal(false); // Close modal after deletion (adjust as per your logic)
  };

  const handleClose = (e) => {
    e.preventDefault();
    if(!stepName) {
      setStepNameMessage("Step Name is mandatory, Add step name or delete the node");
      return;
    }
    setStepNameMessage("");
    setShowModal(false);
  };

  return (
    <div
      className={`modal fade ${showModal ? 'show d-block' : ''}`}
      tabIndex="-1"
      role="dialog"
      style={{ display: showModal ? 'block' : 'none' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-bg-secondary p-3">
            <h5 className="modal-title text-white">Node Information</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
              aria-label="Close"
            ></button>
          </div>
          <Form onSubmit={handleFormSubmit}>
            <div className="modal-body">
              {/* <p>Node ID: {selectedNode?.id}</p>
            <p>Type: {selectedNode?.type}</p> */}
              <p>Label: {selectedNode.data?.label}</p>
              {/* Show the form fields only if the selected node type if default (i.e. hide for start and terminal nodes) */}
              {selectedNode.type == 'default' && (
                <>
                  <Row>
                    <Form.Group className="mb-3 col-12" controlId="step_name">
                      <Form.Label>Step Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="step_name"
                        defaultValue={selectedNode?.data.step_name}
                        onChange={e => setStepName(e.target.value)}
                        value={stepName}
                      />
                      {stepNameMessage && (
                        <Form.Text className="text-danger">{stepNameMessage}</Form.Text>
                      )}                         
                    </Form.Group>
                    <Form.Group className="mb-3 col-12" controlId="action_name">
                      <Form.Label>Action Name</Form.Label>
                      <ReactSelect
                        placeholder="Select Action"
                        options={WfActionList}
                        closeMenuOnSelect={true}
                        onChange={value => setActionName(value)}
                        value={actionName}
                        isClearable={true}
                        isSearchable={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                 
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3 col-12" controlId="sla_id">
                      <Form.Label>SLA</Form.Label>
                      <ReactSelect
                        placeholder="Select SLA"
                        options={slaMasterList}
                        closeMenuOnSelect={true}
                        onChange={value => setSlaId(value)}
                        value={slaId}
                        isClearable={true}
                        isSearchable={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 col-12"
                      controlId="form_definition_id"
                    >
                      <Form.Label>Form</Form.Label>
                      <ReactSelect
                        placeholder="Select Form Definition"
                        options={formMasterList}
                        closeMenuOnSelect={true}
                        onChange={value => setFormDefinitionId(value)}
                        value={formDefinitionId}
                        isClearable={true}
                        isSearchable={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      className="mb-3 col-6"
                      controlId="pre_assigned_user_id"
                    >
                      <Form.Label>Assigned User</Form.Label>
                      <ReactSelect
                        placeholder="Select User"
                        options={WfTaskUsersMasterList}
                        closeMenuOnSelect={true}
                        onChange={value => setPreAssignedUserId(value)}
                        value={preAssignedUserId}
                        isClearable={true}
                        isSearchable={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 col-6"
                      controlId="pre_assigned_user_type_id"
                    >
                      <Form.Label>Assigned Group</Form.Label>
                      <ReactSelect
                        placeholder="Select User Group"
                        options={WfTaskUserTypesMasterList}
                        closeMenuOnSelect={true}
                        onChange={value => setPreAssignedUserTypeId(value)}
                        value={preAssignedUserTypeId}
                        isClearable={true}
                        isSearchable={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      className="mb-3 col-12"
                      controlId="notification_template"
                    >
                      <Form.Label>Notification Template</Form.Label>
                      <ReactSelect
                        placeholder="Select Notifications"
                        options={WfNotificationTemplateList}
                        closeMenuOnSelect={true}
                        onChange={value => setNotificationTemplate(value)}
                        value={notificationTemplate}
                        isClearable={true}
                        isSearchable={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Row>
                </>
              )}
              {/* <Form.Group className="mb-3" controlId="form_definition_id">
                <Form.Label>Form Definition ID</Form.Label>
                <Form.Control
                  type="text"
                  name="form_definition_id"
                  defaultValue={selectedNode?.data.form_definition_id}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pre_assigned_user_id">
                <Form.Label>Pre-assigned User</Form.Label>
                <Form.Control
                  type="text"
                  name="pre_assigned_user_id"
                  defaultValue={selectedNode?.data.pre_assigned_user_id}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="pre_assigned_user_type_id"
              >
                <Form.Label>Pre-assigned User Type</Form.Label>
                <Form.Control
                  type="text"
                  name="pre_assigned_user_type_id"
                  defaultValue={selectedNode?.data.pre_assigned_user_type_id}
                />
              </Form.Group> */}
            </div>
            {selectedNode.type == 'default' && (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                >
                  Delete Node
                </button>
                <button type="submit" className="btn btn-secondary">
                  Save
                </button>
                {/* <Button variant="success" type="submit">
              Save Changes
            </Button> */}
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NodeModal;
